.privacyHeading {
  margin-top: 0px;
  padding-left: 0.2rem;
  color: #000000;
  line-height: 1.6em !important;
  margin-top: 20px !important;
  padding-left: 50px;
  padding-right: 50px;
  opacity: 0.8;
  font-weight: bold;
}

.privacyText {
  margin-top: 0px;
  padding-left: 0.2rem;
  color: #000000;
  line-height: 1.6em !important;
  margin-top: 20px !important;
  padding-left: 50px;
  padding-right: 50px;
  opacity: 0.8;
}

.privacyGridContainer {
  margin-top: 20px !important;
}

.privacyContainer {
  margin-top: 20px !important;
}

.contact {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  padding-left: 50px;
  padding-right: 50px;
}

.contactCard {
  display: flex;
  flex-direction: column;
}

.contactName {
  margin-bottom: 5px;
  font-weight: 500;
}

.contactInfo {
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
  color: #9c0044;
  opacity: 0.7;
  font-size: 0.9em;
}
.contactInfo svg {
  color: #9c0044 !important;
}
