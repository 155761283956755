.header {
  border-bottom: solid 2.4px #eaeaea;
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
}

.headerItems {
  color: #a44f30 !important;
  opacity: 0.6;
  font-weight: bold;
  line-height: 1.5;
  line-height: 1.4em !important;
  font-size: 0.9rem;
  letter-spacing: -0.009em;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 3px;
}

.headerItems a {
  color: inherit;
  text-decoration: none;
}

.headerItems a:hover {
  color: #44343b !important;
}

.headerLinksContainer {
  justify-content: space-between;
  display: flex;
  visibility: visible;
}

.headerIcon {
  display: none !important;
}

.burgerMenu {
  display: none;
  cursor: pointer;
}

@media (max-width: 900px) {
  .headerIcon {
    display: flex !important;
    flex-direction: row-reverse !important;
  }

  /* show the burger menu */
  .burgerMenu {
    display: initial;
    visibility: visible;
  }

  /* hide the header items */
  .headerLinksContainer {
    visibility: hidden;
  }
}
