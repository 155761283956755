.faq {
  margin-bottom: 50px;
  margin-top: 50px;
}

.faqAnswer {
  font-size: 0.98em;
  letter-spacing: 0.04rem;
  opacity: 0.6;
  line-height: 1.6em !important;
  text-align: left;
}

.faqQuestion {
  font-size: 0.98em;
  letter-spacing: 0.04rem;
  opacity: 0.6;
  line-height: 1.6em !important;
  text-align: left;
  font-weight: bold;
}

.faqGridContainer {
  margin-top: 20px !important;
}

.faqContainer {
  margin-top: 20px !important;
}

.processImage img {
  width: 100% !important;
  height: 100% !important;
}
