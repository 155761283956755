.header {
  border-bottom: solid 2.4px #eaeaea;
  position: sticky;
  top: 0;
  background: white;
  z-index: 2;
}

.pageContainer {
  width: 1000px !important;
  margin: 0 auto !important;
}

.pageContent {
  height: 100%;
  padding-top: 0px !important;
}

.productDivider {
  font-size: 16px !important;
  opacity: 0.6;
}

.footerWrapper {
  height: 100% !important;
}
