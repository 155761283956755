.howitworks {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.howitworksText {
  font-size: 0.75rem;
  letter-spacing: 0.2rem;
  margin-top: 0px;
  padding-left: 0.2rem;
  color: #000000;
  opacity: 0.6;
  line-height: 1.6em !important;
  text-align: center;
  margin-top: 20px !important;
}

.howitworksGridContainer {
  margin-top: 20px !important;
}

.howitworksContainer {
  margin-top: 20px !important;
}

.processText {
  padding-bottom: 30px;
}
.steps {
  padding-top: 50px;
}

.steps p {
  margin: 0.7em;
}

.processImage img {
  width: 100% !important;
  height: 100% !important;
}

.problem {
  width: 25%;
  min-width: 250px;
}

.problem img {
  opacity: 0.9 !important;
}

.problemContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: baseline;
}

.problemInfo {
  font-size: 0.98em;
  letter-spacing: 0.04rem;
  opacity: 0.6;
  line-height: 1.6em !important;
  text-align: left;
}

.problemSummary {
  font-size: 0.98em;
  letter-spacing: 0.04rem;
  opacity: 0.6;
  line-height: 1.6em !important;
  text-align: center;
  color: #000000 !important;
  font-weight: bold;
}
