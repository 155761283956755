.blog {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.blogCardWrapper {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}

.blogCard {
  max-width: 400px !important;
  border-radius: 10px !important;
}

.blogContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 150px;
  width: 300px;
}

.blogCreationDate {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  color: #000000;
  opacity: 0.6;
}

.blogTitle {
  font-size: 1.5rem;
  letter-spacing: -0.029375rem;
  font-weight: 600;
  line-height: 2rem;
  color: #9c0044 !important;
  opacity: 0.6;
}

.productCode {
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1rem;
  letter-spacing: 0.09em;
  color: #000000;
  opacity: 0.7;
}
