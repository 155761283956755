.conservPageLink {
  display: flex;
  justify-content: center;
}

.conservPageLink a {
  font-size: 0.9rem;
  letter-spacing: -0.01125rem;
  font-weight: 700;
  color: #9c0044 !important;
  opacity: 0.6;
}

.conservPageLink a:hover {
  color: #44343b !important;
}
