.content {
  font-weight: normal;
  display: inline-block;
  text-align: center !important;
  word-break: break-word;
  color: #666;
  line-height: 1.5em;
  width: auto;
  height: 1.5em;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}

.button {
  background: #f7f7f7 !important;
}
