.postName {
  line-height: 1;
  font-size: 2.5em;
  text-align: left;
  letter-spacing: -0.01em;
  font-weight: 600;
  color: #b55a82 !important;
  opacity: 0.9;
}

.author {
  line-height: 1;
  font-size: 1.25em;
  text-align: left;
  letter-spacing: -0.01em;
  font-weight: 600;
  color: #453c40 !important;
  opacity: 0.8;
}

.date {
  line-height: 1;
  font-size: 1em;
  text-align: left;
  font-weight: bold;
  letter-spacing: -0.01em;
  font-weight: 600;
  opacity: 0.8;
}

.content {
  line-height: 1.5em !important;
  font-weight: 400;
  font-size: 1.15rem;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #525252 !important;
  opacity: 0.8;
}
