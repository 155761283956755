.conservHighlights {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.conservTagline {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .sustainableText {
    font-size: 3rem !important;
  }
  .consciousText {
    margin-left: 0px !important;
    font-size: 3rem !important;
  }
  .futureText {
    font-size: 3rem !important;
    margin-right: 0px !important;
  }
}

.sustainableText {
  margin-top: 0px;
  background-repeat: repeat;
  background-clip: none;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3.5rem;
  text-align: left;
  font-weight: bold;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  background-image: linear-gradient(90deg, #588a05, #e9eae7);
}

.consciousText {
  margin-top: -45px;
  margin-left: 90px;
  background-repeat: repeat;
  background-clip: none;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3.5rem;
  text-align: right;
  font-weight: bold;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  background-image: linear-gradient(90deg, #6c8740, #825343);
}

.futureText {
  margin-top: -45px;
  background-repeat: repeat;
  background-clip: none;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3.5rem;
  text-align: left;
  margin-right: 80px;
  font-weight: bold;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  background-image: linear-gradient(90deg, #b5aeac, #a44f30);
}

.introductionText {
  color: #000000;
  opacity: 0.6;
  font-weight: 400;
  line-height: 1.5;
  line-height: 1.6em !important;
  font-weight: 400;
  font-size: 1.25rem;
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: 60px;
}

.infoText {
  color: #000000;
  opacity: 0.6;
  font-weight: 400;
  line-height: 1.5;
  line-height: 1.6em !important;
  font-weight: 400;
  font-size: 1.25rem;
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: 30px;
}

.infoPlastic {
  color: #ff0000;
  opacity: 0.6;
  font-weight: 400;
  line-height: 1.5;
  line-height: 1.6em !important;
  font-weight: 400;
  font-size: 1.25rem;
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: -10px;
}

.infoPaper {
  color: #00ff00;
  opacity: 0.6;
  font-weight: 400;
  line-height: 1.5;
  line-height: 1.6em !important;
  font-weight: 400;
  font-size: 1.25rem;
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: -10px;
}

.infoAgri {
  color: #000000;
  opacity: 0.6;
  font-weight: 400;
  line-height: 1.5;
  line-height: 1.6em !important;
  font-weight: 400;
  font-size: 1.25rem;
  letter-spacing: -0.02em;
  text-align: center;
  margin-bottom: 10px;
}

.solutionText {
  font-size: 0.98em;
  letter-spacing: 0.04rem;
  opacity: 0.6;
  line-height: 1.6em !important;
  text-align: left;
  margin-left: 15px;
}

.introductionPhrase {
  font-size: 1.25rem;
  letter-spacing: 0.2rem;
  color: #000000;
  opacity: 0.6;
  line-height: 1.6em !important;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0px;
}
.problem {
  width: 25%;
  min-width: 250px;
}

.problem img {
  opacity: 0.9 !important;
}

.problemContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: baseline;
}

.problemInfo {
  font-size: 0.98em;
  letter-spacing: 0.04rem;
  opacity: 0.6;
  line-height: 1.6em !important;
  text-align: left;
}

.problemSummary {
  font-size: 1em;
  letter-spacing: 0.04rem;
  opacity: 0.6;
  line-height: 1.6em !important;
  text-align: center;
}

.conservSolution {
  display: flex;
  margin-top: 40px;
  align-items: center;
  margin-bottom: 50px;
  justify-content: center;
  flex-wrap: wrap;
}

.conservSolutionHeading {
  background-repeat: repeat;
  background-clip: none;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.5em;
  text-align: left;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  background-image: linear-gradient(90deg, #9c0044, #b55a82);
  opacity: 0.7;
  text-align: center;
}
