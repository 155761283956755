.burgerMenu {
  padding: 20px 20px;
  background-color: inherit;
  border-bottom: solid 2.4px #eaeaea;
  position: sticky;
  top: 100px;
  background: white;
  z-index: 2;
}

.headerItems {
  color: #a44f30 !important;
  opacity: 0.6;
  font-weight: bold;
  line-height: 1.5;
  line-height: 1.4em !important;
  font-size: 1.2rem;
  letter-spacing: -0.009em;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 3px;
}

.headerItems a {
  color: inherit;
  text-decoration: none;
}

.headerItems a:hover {
  color: #44343b !important;
}

.burgerMenuImage {
  visibility: hidden;
  cursor: pointer;
}

@media (max-width: 900px) {
  /* show the burger menu */
  .burgerMenuImage {
    visibility: visible;
  }
}
