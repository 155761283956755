.productDivider {
  font-size: 16px !important;
  opacity: 0.6;
  margin-top: 50px !important;
}

.contactForm {
  margin-top: 50px !important;
}

.formContainer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.formItemGroup {
  display: flex;
  margin-bottom: 50px;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.formItem {
  margin: 10px;
  margin-right: 20px;
  width: 45%;
}

.formItem label {
  font-size: 0.9em !important;
  color: #a44f30 !important;
  opacity: 0.7 !important;
  font-weight: 500 !important;
}

.formItem .textLabel {
  font-size: 0.8em !important;
  color: #a44f30 !important;
  opacity: 0.7 !important;
  font-weight: 500 !important;
  line-height: 1.5;
  display: block;
  padding: 0 0 0 1px;
  margin-bottom: 0.5em;
}

.contactFormSubmit {
  display: flex;
  padding-right: 30px;
  padding-top: 50px;
  flex-direction: column;
  align-items: flex-end;
}

.feedbackText {
  font-size: 0.8em;
  opacity: 0.7;
  color: #9c0044 !important;
}
