.footerWrapper {
  height: 100%;
  position: relative;
}

.footerImage {
  margin-top: 7px;
  margin-left: -30px;
}

.footerContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: baseline;
  margin-top: 50px;
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media (max-width: 800px) {
  .footerContainer {
    flex-direction: column;
  }
}

.footerElement {
  max-width: 300px;
  margin-bottom: 20px;
}

.footerText {
  color: #000000;
  opacity: 0.6;
  font-weight: 400;
  font-weight: 800;
  line-height: 1.5;
  line-height: 1.4em !important;
  font-weight: 400;
  font-size: 0.98rem;
  letter-spacing: -0.009em;
  text-align: left;
}

.quickLinksTitle {
  font-size: 0.98em;
  letter-spacing: 0.04rem;
  opacity: 0.6;
  line-height: 1.6em !important;
  text-align: left;
  color: #000000 !important;
  font-weight: bold;
}

.quickLinks {
  color: #a44f30 !important;
  opacity: 0.6;
  font-weight: bold;
  line-height: 1.5;
  line-height: 1.4em !important;
  font-size: 0.9rem;
  letter-spacing: -0.009em;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 3px;
}

.quickLinks a {
  color: inherit;
  text-decoration: none;
}

.quickLinksContainer {
  margin-top: 10px;
}

.contactContainer {
  margin-top: 10px;
}

.contactDetail {
  color: #000000;
  opacity: 0.6;
  font-weight: 400;
  font-weight: 800;
  line-height: 1.5;
  line-height: 1.4em !important;
  font-weight: 400;
  font-size: 0.9rem;
  letter-spacing: -0.009em;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 3px;
}

.introductionPhrase {
  font-size: 0.75rem;
  letter-spacing: 0.2rem;
  color: #000000;
  opacity: 0.6;
  line-height: 1.6em !important;
  text-align: center;
  text-transform: uppercase;
}

.copyRightText {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.65;
  font-size: 0.8em;
  font-weight: 400;
  min-width: 320px;
  direction: ltr;
  font-feature-settings: 'kern';
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  color: #a44f30;
  opacity: 0.7;
  border-top: 2.4px solid rgb(234, 234, 234);
  padding: 15px;
}

.copyRightText a {
  padding-left: 6px;
}

.copyRightText a svg {
  color: #a44f30 !important;
  fill: #a44f30 !important;
  margin-top: 3px !important;
}

.developerDetails {
  display: flex;
  align-items: center;
}
